<template>
   <div>
      <div class="listToggle awwardsLead">
         <!-- Nested Tabs -->
         <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item" @click="showTimesBox">
               <div class="nav-link" :class="{ 'active': activeTab === 'today' }" @click="activateTab('today')"
                  data-toggle="tab" href="#today" role="tab">Today</div>
            </li>
            <li class="nav-item" @click="hideTimesBox">
               <div class="nav-link" :class="{ 'active': activeTab === 'yesterday' }" @click="activateTab('yesterday')"
                  data-toggle="tab" href="#yesterday" role="tab">Yesterday</div>
            </li>
         </ul>
         <div class="timesBox" v-show="isTimesBoxVisible">
            <CounterdownCompo :isVisible="true" :showDays="false" />
         </div>
      </div>
      <div class="tab-content">
         <div class="tab-pane" :class="{ 'active': activeTab === 'today' }" id="today" role="tabpanel">
            <!-- Content for Nested Tab 1 -->
            <div class="tab-contentWrap">
               <div class="tab-content-item" v-if="isLoadedToday">
                  <TopCharmDailyTodayTabCont />
               </div>
            </div>
         </div>
         <div class="tab-pane" :class="{ 'active': activeTab === 'yesterday' }" id="yesterday" role="tabpanel">
            <div class="tab-contentWrap">
               <div class="tab-content-item" v-if="isLoadedYesterday">
                  <TopCharmDailyYesterdayTabCont />
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import TopCharmDailyTodayTabCont from './TopCharmDailyTodayTabCont.vue';
import TopCharmDailyYesterdayTabCont from './TopCharmDailyYesterdayTabCont.vue';
import CounterdownCompo from '../../components/CounterdownCompo.vue'

export default {
   name: 'TopCharmDailyInnerTabs',
   components: {
      TopCharmDailyTodayTabCont,
      TopCharmDailyYesterdayTabCont,
      CounterdownCompo
   },
   data() {
      return {
         activeTab: 'today',
         isLoadedToday: false,
         isLoadedYesterday: false,
         isTimesBoxVisible: true,
      };
   },
   methods: {
      showTimesBox() {
         this.isTimesBoxVisible = true;
      },
      hideTimesBox() {
         this.isTimesBoxVisible = false;
      },
      activateTab(tabId) {
         this.activeTab = tabId;
         if (tabId === 'today' && !this.isLoadedToday) {
            this.isLoadedToday = true;
         }
         if (tabId === 'yesterday' && !this.isLoadedYesterday) {
            this.isLoadedYesterday = true;
         }
      },         
   },
   mounted() {
      this.isLoadedToday = true;
   }
}
</script>