<template>
   <div>
      <div class="listToggle awwardsLead">
         <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item" @click="showTimesBox">
               <div class="nav-link" :class="{ 'active': activeTab === 'thisweek' }" @click="activateTab('thisweek')"
                  data-toggle="tab" href="#thisweek" role="tab">This Week</div>
            </li>
            <li class="nav-item" @click="hideTimesBox">
               <div class="nav-link" :class="{ 'active': activeTab === 'lastweek' }" @click="activateTab('lastweek')"
                  data-toggle="tab" href="#lastweek" role="tab">Last Week</div>
            </li>
         </ul>
         <div class="timesBox" v-show="isTimesBoxVisible">
            <CounterdownCompo :isVisible="true" :showDays="true" />
         </div>
      </div>
      <div class="tab-content">
         <div class="tab-pane" :class="{ 'active': activeTab === 'thisweek' }" id="thisweek" role="tabpanel">
            <div class="tab-contentWrap">
               <div class="tab-content-item" v-if="isLoadedThisweek">
                  <TopCharmWeeklyThisWeekTabCont :countryName="selectedCountryName" />
               </div>
            </div>
         </div>
         <div class="tab-pane" :class="{ 'active': activeTab === 'lastweek' }" id="lastweek" role="tabpanel">
            <div class="tab-contentWrap">
               <div class="tab-content-item" v-if="isLoadedLastweek">
                  <TopCharmWeeklyLastWeekTabCont :countryName="selectedCountryName" />
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import TopCharmWeeklyThisWeekTabCont from './TopCharmWeeklyThisWeekTabCont.vue';
import TopCharmWeeklyLastWeekTabCont from './TopCharmWeeklyLastWeekTabCont.vue';
import CounterdownCompo from '../../components/CounterdownCompo.vue'
export default {
   name: 'TopCharmWeeklyInnerTabs',
   props: {
      selectedCountryName: String
   },
   components: {
      TopCharmWeeklyThisWeekTabCont,
      TopCharmWeeklyLastWeekTabCont,
      CounterdownCompo
   },
   data() {
      return {
         activeTab: 'thisweek',
         isLoadedThisweek: false,
         isLoadedLastweek: false,
         isTimesBoxVisible: true,
         weeklyFormatTime: ''
      };
   },
   methods: {
      activateTab(tabId) {
         this.activeTab = tabId;
         if (tabId === 'thisweek' && !this.isLoadedThisweek) {
            this.isLoadedThisweek = true;
         }
         if (tabId === 'lastweek' && !this.isLoadedLastweek) {
            this.isLoadedLastweek = true;
         }
      },
      showTimesBox() {
         this.isTimesBoxVisible = true;
      },
      hideTimesBox() {
         this.isTimesBoxVisible = false;
      },      
   },
   mounted() {
        this.isLoadedThisweek = true;
    },
}
</script>