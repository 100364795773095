<template>
    <div>
        <div class="historyTab-wrapper">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <div class="nav-link" :class="{ 'active': activeTab === 'history' }" @click="activateTab('history')"
                        data-toggle="tab" href="#history" role="tab">History</div>
                </li>
                <li class="nav-item">
                    <div class="nav-link" :class="{ 'active': activeTab === 'myhistory' }"
                        @click="activateTab('myhistory')" data-toggle="tab" href="#myhistory" role="tab">My History
                    </div>
                </li>
            </ul>
        </div>
        <div class="tab-content">
            <div class="tab-pane" :class="{ 'active': activeTab === 'history' }" id="history" role="tabpanel">
                <div class="tab-contentIn" v-if="isLoadedHistory">
                    <CarGameHistory />
                </div>
            </div>
            <div class="tab-pane" :class="{ 'active': activeTab === 'myhistory' }" id="myhistory" role="tabpanel">
                <div class="tab-contentIn" v-if="isLoadedMyHistory">
                    <CarGameMyHistory />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CarGameHistory from './CarGameHistory.vue'
import CarGameMyHistory from './CarGameMyHistory.vue'
export default {
    name: 'HistoryTabComponent',
    components: {
        CarGameHistory,
        CarGameMyHistory
    },
    data(){
        return {
            activeTab: 'history',
            isLoadedHistory: false,
            isLoadedMyHistory: false,
        }
    },
    methods: {
        activateTab(tabId) {
            this.activeTab = tabId;
            if (tabId === 'history' && !this.isLoadedHistory) {
                this.isLoadedHistory = true;
            }
            if (tabId === 'myhistory' && !this.isLoadedMyHistory) {
                this.isLoadedMyHistory = true;
            }
        }
    },
    mounted() {
        this.isLoadedHistory = true;
    },
}
</script>