<template>
    <div>
        <LoadingOverlay :loading="loading" />
        <div class="listingPattern">
            <div class="fixHeight">
                <div class="listBoxes" v-for="(reward, index) in yesterdayData" :key="reward.id"
                @click="redirectProfile(reward.user_id, reward.profile_id, reward.charm_level)">
                    <div class="leftBxb">
                        <div class="s-no">{{ index + 1 }}</div>
                        <div class="listIconBox">
                            <div class="profPic">
                                <img :src='(reward.user.profile_images.length > 0) ? reward.user.profile_images[0].image_name: defaultUserImage' class="user" alt="User Image" />
                            </div>
                        </div>
                        <span>
                            <div :class="{ 'marqueeContainer': reward.user.name.length > 8 }">
                                <div v-if="reward.user.name.length > 8" class="marquee">
                                    <span class="marquee-content">{{ reward.user.name }}</span>
                                </div>
                                <div v-else>{{ reward.user.name }}</div>
                            </div>
                            <div class="base">
                                <img :src='reward.user.last_rich_level_icon' alt="Yellow Label" /><b>{{
                    reward.user.rich_level }}</b>
                            </div>
                        </span>
                    </div>
                    <div class="rightBxb">
                        <span>
                            <img :src='diamondIcon' alt="Diamond Icon" />{{ reward.total_bet }}
                            <br /><span class="rewards" v-if="index < 10">Reward {{ reward.reward_coin }}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import diamondIcon from '../../assets/images/diamond.png';
import defaultUserImage from '../../assets/images/default_profile.jpeg';
import LoadingOverlay from '../LoadingOverlay.vue'
export default {
    name: 'LuckyNumberYesterdayTabCont',
    components: {
        LoadingOverlay
    },
    data() {
        return {
            yesterdayData: [],
            diamondIcon: diamondIcon,
            defaultUserImage:defaultUserImage,
            loading: false,
        }
    },
    mounted() {
        this.fetchYesterdayData();
    },
    methods: {
        fetchYesterdayData() {
            this.loading = true;
            axios.get('https://zeep.live/api/top-luckynumber-game?type=yesterday').then(response => {
                this.yesterdayData = response.data.result.data;
                this.checkAllImagesExist();
            })
                .catch(error => {
                    console.error('Error fetching Yesterday rewards:', error);
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        checkAllImagesExist() {
            this.yesterdayData.forEach(reward => {
                if (reward.user.profile_images.length > 0) {
                    const imageUrl = reward.user.profile_images[0].image_name;
                    this.checkImageExists(imageUrl)
                        .then(exists => {
                            if (!exists) {
                                reward.user.profile_images[0].image_name = this.defaultUserImage;
                            }
                        });
                }
            });
        },
        checkImageExists(imageUrl) {
            return new Promise((resolve, reject) => {
                var img = new Image();
                img.onload = function() {
                    resolve(true);
                };
                img.onerror = function() {
                    resolve(false);
                };
                img.src = imageUrl;
            });
        },

        // Get User info on click 
        redirectProfile(id, profile_id, level) {
            // if (window.navigator.userAgent.indexOf("Android") > -1) {
            // const baseURL = 'female-weekly-rewards';
            const baseURL = '#';
            const params = {
                id: id,
                profile_id: profile_id,
                level: level
            };

            const queryString = Object.keys(params)
                .map(key => `${key}=${params[key]}`)
                .join('&');
            const url = `${baseURL}?${queryString}`;
            window.location.href = url;
            // Android.redirectProfile(url);
            // }
        }

    }
}
</script>