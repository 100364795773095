<template>
    <div class="carSpeedRankContainer">
        <div class="backgroundWrapper">
            <div class="top-headerBox">
                <div class="top-headerBoxIn">
                    <div class="backToApp" id="close" @click="viewClose('someStatus')">
                        <div class="angle white-angle"></div>
                    </div>
                    <div class="top-header-HD">
                        <h2 class="white-txt">Speed Rank</h2>
                    </div>
                </div>
                <div class="speedRank-topSec">
                    <h3 class="txtb">{{ this.CarSpeedRankData.selectedRoadName }}</h3>
                    <img class="date-bg" :src="roadNameBG" alt="Date Bg" />
                    <img :src="getHighwayImageSrc(this.CarSpeedRankData.selectedRoadName)" class="highway"
                        :alt="this.CarSpeedRankData.selectedRoadName" />
                </div>
            </div>
            <div class="speedRank-listWrapper">
                <ul>
                    <li v-for="(roadSpeed, carName, index) in roadSpeedData" :key="roadSpeed"
                        :class="{ 'active': isSelectedCar(carName) }">
                        <div class="speedRank-listInnerBox">
                            <div class="leftBxb">
                                <div class="s-no">{{ index + 1 }}</div>
                            </div>
                            <div class="middleBox1">
                                <div class="topGame-profPic">
                                    <img :src="getCarImageSrc(carName).imagePath" :alt="carName" class="car-image" />
                                </div>
                            </div>
                            <div class="middleBox2">
                                <span>{{ carName }}</span>
                            </div>
                            <div class="rightBxb">
                                <div>{{ roadSpeed }}
                                    <span class="speed-image-icon">
                                        <img :src="getSpeedMeterIcon(isSelectedCar(carName))" class="speedMeterIcon"
                                            :alt="isSelectedCar(carName) ? 'Active Speed Meter Icon' : 'Speed Meter Icon'" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import roadNameBG from '../../assets/images/car-game/highway/road_name_bg.png'
import speedMeterIcon from '../../assets/images/car-game/speed_meter_icon.png'
import activeSpeedMeterIcon from '../../assets/images/car-game/speed_meter_icon-active.png'
export default {
    name: 'CarGameSpeedRank',
    data() {
        return {
            CarSpeedRankData: {
                selectedRoadName: '',
                selectedCars: []
            },
            roadSpeedData: {},
            roadNameBG: roadNameBG,
            speedMeterIcon: speedMeterIcon,
            activeSpeedMeterIcon: activeSpeedMeterIcon,
            defaultHighwayImage: require('../../assets/images/car-game/highway/Highway.png')
        }
    },
    mounted() {
        this.contestId = this.$route.params.contest_id;
        this.fetchCarSpeedRankData();
    },
    methods: {
        fetchCarSpeedRankData() {
            const apiUrl = `https://zeep.live/api/cargame/speed_rankapi.php?contest_id=${this.contestId}`;
            axios.get(apiUrl)
                .then(response => {
                    this.CarSpeedRankData = response.data.result;
                    this.roadSpeedData = response.data.result.roadSpeedData;
                    console.warn(apiUrl)
                })
                .catch(error => {
                    console.error("Error Featching CarSpeed Rank Data", error)
                })
        },
        getHighwayImageSrc(highwayImage) {
            if (!highwayImage) return '';
            const sanitizedHighwayImageName = highwayImage.replace(/\s+/g, '');
            try {
                return require(`../../assets/images/car-game/highway/${sanitizedHighwayImageName}.png`);
            } catch (e) {
                console.error(`Image not found for ${sanitizedHighwayImageName}, using default image.`);
                return this.defaultHighwayImage;
            }
        },
        getCarImageSrc(carName) {
            const sanitizedImageName = carName.replace(/\s+/g, '');
            try {
                const imagePath = require(`../../assets/images/car-game/${sanitizedImageName}.png`);
                const speed = this.roadSpeedData[carName] || 'N/A';
                return { imagePath, speed };
            } catch (e) {
                console.error(`Image not found for ${sanitizedImageName}, using default image.`);
                return { imagePath: '', speed: 'N/A' };
            }
        },
        isSelectedCar(carName) {
            return this.CarSpeedRankData.selectedCars.includes(carName);
        },
        getSpeedMeterIcon(isActive) {
            return isActive ? this.activeSpeedMeterIcon : this.speedMeterIcon;
        },

        //Close Webview on back arrow click
        viewClose(status) {
            if (window.navigator.userAgent.indexOf("Android") > -1) {
                Android.viewClose(status);
            }
        },
    }
}
</script>