<template>
    <div class="historyDetails-wrapper">
        <div class="historyDetails-topHeadBox">
            <div class="backToApp" id="close" @click="viewClose('someStatus')">
                <div class="angle"></div>
            </div>
            <div class="historyDetails-topHD">
                <h2>Rule</h2>
            </div>
        </div>
        <div class="ruleInfo-wrapper">
            <div class="ruleContent">
                <p>The odds of each cars are calculated according to dynamic odds.</p>
                <p>Prize winning = Betting amount for the winning car* Dynamics odds for that car.</p>
                <p>Dynamic odds = total bet amount / total bet amount of this car.</p>
                <p>when the dynamic odds are higher than the standard odds, a part of the prizes will be distributed to the host and guests.</p>                               
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CarGameRules',
    methods: {
        viewClose(status) {
            if (window.navigator.userAgent.indexOf("Android") > -1) {
                Android.viewClose(status);        
            }
        }
    }
}
</script>