<template>
   <div>
      <LoadingOverlay :loading="loading" />
      <div class="rewards-bg">
         <h3 class="txtb">This Week</h3>
         <img :src='pinkBarBg' class="date-bg" alt="Pink Topbar Image" />
         <div class="afterbefore"></div>
         <img class="list-top-bg" :src='pinkBackgroundImage' alt="Pink Background Image" />
         <div class="daily-reward-box rewardPhoto">
            <div class="wrapDiv">
               <div class="coin-box">
                  <div class="coin-text">
                     <div class="text-white"
                        @click="redirectProfile(topCharmThisweekData[1].id, topCharmThisweekData[1].profile_id, topCharmThisweekData[1].charm_level)">
                        <div class="img-img">
                           <div class="overlap">
                              <img :src='zhibojianFansSecond' alt="Frame Image">
                              <div class="coin-profPic" v-if="topCharmThisweekData.length > 1">
                                 <img class="overlapImg" :src='topCharmThisweekData[1].profile_images[0].image_name'
                                    alt="User Image" />
                              </div>
                           </div>
                           <img class="smallpoint" :src='rank2' alt="Rank 2">
                           <div class="pointsBox" v-if="topCharmThisweekData.length > 1">
                              <img class="points" :src='topCharmThisweekData[1].current_charm_level_icon'
                                 alt="Label Image">
                              <span class="value">{{ topCharmThisweekData[1].charm_level }}</span>
                           </div>
                        </div>
                        <div class="mt-20" v-if="topCharmThisweekData.length > 1">
                           <span class="name">
                              <div :class="{ 'marqueeContainer': topCharmThisweekData[1].name.length > 8 }">
                                 <div v-if="topCharmThisweekData[1].name.length > 8" class="marquee">
                                    <span class="marquee-content">{{ topCharmThisweekData[1].name }}</span>
                                 </div>
                                 <div v-else>{{ topCharmThisweekData[1].name }}</div>
                              </div>
                           </span>
                           <span class="spanBox beans-box">
                              <img :src='beansIcon' alt="Beans Icon" />{{ topCharmThisweekData[1].weekly_earning_beans
                              }}
                           </span>
                        </div>
                     </div>
                  </div>
                  <div class="coin-text active">
                     <div class="text-white"
                        @click="redirectProfile(topCharmThisweekData[0].id, topCharmThisweekData[0].profile_id, topCharmThisweekData[0].charm_level)">
                        <div class="img-img">
                           <div class="overlap">
                              <img class="activeZoom" :src='zhibojianFansFirst' alt="Frame Image" />
                              <div class="coin-profPicActive" v-if="topCharmThisweekData.length > 0">
                                 <img class="overlapImg overlapActive"
                                    :src='topCharmThisweekData[0].profile_images[0].image_name' alt="User Image" />
                              </div>
                           </div>
                           <img class="smallpoint" :src='rank1' alt="Rank 1" />
                           <div class="pointsBox" v-if="topCharmThisweekData.length > 0">
                              <img class="points" :src='topCharmThisweekData[0].current_charm_level_icon'
                                 alt="Label Image">
                              <span class="value">{{ topCharmThisweekData[0].charm_level }}</span>
                           </div>
                        </div>
                        <div v-if="topCharmThisweekData.length > 0">
                           <span class="name" style="text-align: center;">
                              <div :class="{ 'marqueeContainer': topCharmThisweekData[0].name.length > 8 }">
                                 <div v-if="topCharmThisweekData[0].name.length > 8" class="marquee">
                                    <span class="marquee-content">{{ topCharmThisweekData[0].name }}</span>
                                 </div>
                                 <div v-else>{{ topCharmThisweekData[0].name }}</div>
                              </div>
                           </span>
                           <span class="spanBox beans-box" style="text-align: center;">
                              <img :src='beansIcon' alt="Beans Icon" />
                              {{ topCharmThisweekData[0].weekly_earning_beans }}
                           </span>
                        </div>
                     </div>
                  </div>
                  <div class="coin-text">
                     <div class="text-white"
                        @click="redirectProfile(topCharmThisweekData[2].id, topCharmThisweekData[2].profile_id, topCharmThisweekData[2].charm_level)">
                        <div class="img-img">
                           <div class="overlap">
                              <img :src='zhibojianFansThird' alt="Frame Image">
                              <div class="coin-profPic" v-if="topCharmThisweekData.length > 2">
                                 <img class="overlapImg" :src='topCharmThisweekData[2].profile_images[0].image_name'
                                    alt="User Image" />
                              </div>
                           </div>
                           <img class="smallpoint" :src='rank3' alt="Rank 3">
                           <div class="pointsBox" v-if="topCharmThisweekData.length > 2">
                              <img class="points" :src='topCharmThisweekData[2].current_charm_level_icon'
                                 alt="Label Image">
                              <span class="value">{{ topCharmThisweekData[2].charm_level }}</span>
                           </div>
                        </div>
                        <div class="mt-20" v-if="topCharmThisweekData.length > 2">
                           <span class="name">
                              <div :class="{ 'marqueeContainer': topCharmThisweekData[2].name.length > 8 }">
                                 <div v-if="topCharmThisweekData[2].name.length > 8" class="marquee">
                                    <span class="marquee-content">{{ topCharmThisweekData[2].name }}</span>
                                 </div>
                                 <div v-else>{{ topCharmThisweekData[2].name }}</div>
                              </div>
                           </span>
                           <span class="spanBox beans-box">
                              <img :src='beansIcon' alt="Beans Icon" />
                              {{ topCharmThisweekData[2].weekly_earning_beans }}
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="podium">
                  <img class="podiumImg" :src='pinkPodiumBg' alt="Podium Image" />
               </div>
            </div>
         </div>
         <!-- User List Section -->
         <div class="listingPattern">
            <div class="fixHeight">
               <div class="listBoxes" v-for="(reward, index) in topCharmThisweekData.slice(3) " :key="reward.id"
                  @click="redirectProfile(reward.id, reward.profile_id, reward.charm_level)">
                  <div class="leftBxb">
                     <div class="s-no">{{ index + 4 }}</div>
                     <div class="listIconBox">
                        <div class="profPic">
                           <!-- <img v-if="reward.profile_images && reward.profile_images.length > 0" :src="reward.profile_images[0].image_name" class="user" alt="" />
                        <img v-else :src='defaultProfile' class="user" alt="" /> -->
                           <img
                              :src='(reward.profile_images.length > 0) ? reward.profile_images[0].image_name : defaultUserImage'
                              class="user" alt="User Image" />

                        </div>
                        <img class="frames" :src='reward.current_pic_frame' alt="" />
                     </div>
                     <span>
                        <div :class="{ 'marqueeContainer': reward.name.length > 8 }">
                           <div v-if="reward.name.length > 8" class="marquee">
                              <span class="marquee-content">{{ reward.name }}</span>
                           </div>
                           <div v-else>{{ reward.name }}</div>
                        </div>
                        <div class="base">
                           <img :src='reward.current_charm_level_icon' alt="" />
                           <b>{{ reward.charm_level }}</b>
                        </div>
                     </span>
                  </div>
                  <div class="rightBxb">
                     <span><img :src='beansIcon' alt="Beans Icon" />{{ reward.weekly_earning_beans
                        }}</span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

</template>
<script>
import axios from 'axios';
import defaultUserImage from '../../assets/images/default_profile.jpeg';
import pinkBarBg from '../../assets/images/pink-bar-bg.png'
import pinkBackgroundImage from '../../assets/images/pink-background-image.png';
import zhibojianFansSecond from '../../assets/images/zhibojian_fans_sec.png';
import zhibojianFansFirst from '../../assets/images/zhibojian_fans_first.png';
import zhibojianFansThird from '../../assets/images/zhibojian_fans_thi.png';
import rank1 from '../../assets/images/rank-1.png';
import rank2 from '../../assets/images/rank-2.png';
import rank3 from '../../assets/images/rank-3.png';
import beansIcon from '../../assets/images/beans-icon.png';
import pinkPodiumBg from '../../assets/images/pink-podium-bg.png';
import LoadingOverlay from '../LoadingOverlay.vue'
export default {
   name: 'TopCharmWeeklyThisWeekTabCont',
   props: {
      countryName: String
   },
   components: {
      LoadingOverlay
   },
   data() {
      return {
         topCharmThisweekData: [],
         pinkBarBg: pinkBarBg,
         pinkBackgroundImage: pinkBackgroundImage,
         zhibojianFansSecond: zhibojianFansSecond,
         zhibojianFansFirst: zhibojianFansFirst,
         zhibojianFansThird: zhibojianFansThird,
         beansIcon: beansIcon,
         rank1: rank1,
         rank2: rank2,
         rank3: rank3,
         pinkPodiumBg: pinkPodiumBg,
         defaultUserImage: defaultUserImage,
         loading: false,
      }
   },
   watch: {
      countryName(newCountryName, oldCountryName) {
         if (newCountryName !== oldCountryName) {
            this.fetchTopCharmThisweekData();
         }
      }
   },
   mounted() {
      this.fetchTopCharmThisweekData();
   },
   methods: {
      fetchTopCharmThisweekData() {
         this.loading = true;
         const apiUrl = `https://zeep.live/api/top-charm-weekly-daily?type=this_week&country=${this.countryName}`;
         axios.get(apiUrl).then(response => {
            this.topCharmThisweekData = response.data.result.data;
            this.checkAllImagesExist();
            //console.warn("Ths week Api", apiUrl)
         })
            .catch(error => {
               console.error("Error Featching This Week Data", error)
            })
            .finally(() => {
               this.loading = false;
            })
      },
      checkAllImagesExist() {
         this.topCharmThisweekData.forEach(reward => {
            if (reward.profile_images.length > 0) {
               const imageUrl = reward.profile_images[0].image_name;
               this.checkImageExists(imageUrl)
                  .then(exists => {
                     if (!exists) {
                        reward.profile_images[0].image_name = this.defaultUserImage;
                     }
                  });
            }
         });
      },
      checkImageExists(imageUrl) {
         return new Promise((resolve, reject) => {
            var img = new Image();
            img.onload = function () {
               resolve(true);
            };
            img.onerror = function () {
               resolve(false);
            };
            img.src = imageUrl;
         });
      },
      // Get User info on click 
      redirectProfile(id, profile_id, level) {
         if (window.navigator.userAgent.indexOf("Android") > -1) {
            const baseURL = 'female-weekly-rewards';
            const params = {
               id: id,
               profile_id: profile_id,
               level: level
            };
            const queryString = Object.keys(params)
               .map(key => `${key}=${params[key]}`)
               .join('&');
            const url = `${baseURL}?${queryString}`;
            Android.redirectProfile(url);
         }
      }
   }
}
</script>