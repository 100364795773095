<template>
    <div class="historyDetails-wrapper">
        <div class="historyDetails-topHeadBox">
            <div class="backToApp" id="close" @click="viewClose('someStatus')">
                <div class="angle"></div>
            </div>
            <div class="historyDetails-topHD">
                <h2>Rule</h2>
            </div>
        </div>
        <div class="ruleInfo-wrapper">
            <div class="ruleContent">
                <p>In LUCKY SEVEN GAME two dice are thrown, and the sum of the numbers determines the result.</p>
                <p>Players can place a bet on three options 2 to 6, Lucky 7, & 8 to 12.</p> 
                <p>If the sum falls within 2 to 6 or 8 to 12, the player wins 2x the bet amount. If the sum is exactly 7, the player hits the jackpot and wins 5x the bet.</p> 
                <p>Players can able to bet multiple time in single game and payouts are based on the result of the dice.</p>
                
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'LuckySevenRules',
    methods: {
        viewClose(status) {
            if (window.navigator.userAgent.indexOf("Android") > -1) {
                Android.viewClose(status);        
            }
        }
    }
}
</script>