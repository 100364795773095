<template>
   <div>
      <LoadingOverlay :loading="loading" />
      <div class="fixHeight">
         <div class="listBoxes" v-for="(reward, index) in thisWeekData" :key="reward.id">
            <div class="leftBxb">
               <h4 v-if="index > 2">{{ index + 1 }}</h4>
               <img v-else-if="index === 0" :src='rank1' class="rankNumbImage" alt="Reward Rank Image" />
               <img v-else-if="index === 1" :src='rank2' class="rankNumbImage" alt="Reward Rank Image" />
               <img v-else-if="index === 2" :src='rank3' class="rankNumbImage" alt="Reward Rank Image" />
               <div class="listIconBox">
                  <div class="profPic">
                     <img :src='defaultProfile' class="user" alt="User Image" />
                  </div>
               </div>
               <span>
                  <div :class="{ 'marqueeContainer': reward.user.name.length > 8 }">
                     <div v-if="reward.user.name.length > 8" class="marquee">
                        <span class="marquee-content">{{ reward.user.name }}</span>
                     </div>
                     <div v-else>{{ reward.user.name }}</div>
                  </div>
               </span>
            </div>
            <div class="rightBxb">
               <span>
                  <img :src='beansIcon' alt="Beans Icon" />{{ reward.total_coin_earned }}
               </span>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import axios from 'axios';
import rank1 from '../../assets/images/rank-1.png';
import rank2 from '../../assets/images/rank-2.png';
import rank3 from '../../assets/images/rank-3.png';
import beansIcon from '../../assets/images/beans-icon.png';
import defaultProfile from '../../assets/images/default_profile.jpeg'
import LoadingOverlay from '../LoadingOverlay.vue'
export default {
   name: 'TopAgencyThisWeekListDetailsComp',
   components: {
      LoadingOverlay
   },
   data() {
      return {
         thisWeekData: [],
         rank1: rank1,
         rank2: rank2,
         rank3: rank3,
         beansIcon: beansIcon,
         defaultProfile: defaultProfile,
         loading: false,
      }
   },
   mounted() {
      this.fetchWeeklyRewards();
   },
   methods: {
      fetchWeeklyRewards() {
         this.loading = true;
         axios.get('https://zeep.live/api/top-agency-weekly?type=this_week').then(response => {
            this.thisWeekData = response.data.result.data;

         })
            .catch(error => {
               console.error('Error fetching weekly rewards:', error);
            })
            .finally(() => {
               this.loading = false;
            })
      },
   }
}
</script>