<template>
    <div class="historyDetails-wrapper">
        <div class="historyDetails-topHeadBox">
            <div class="backToApp" id="close" @click="viewClose('someStatus')">
                <div class="angle"></div>
            </div>
            <div class="historyDetails-topHD">
                <h2>Rule</h2>
            </div>
        </div>
        <div class="ruleInfo-wrapper">
            <div class="ruleContent">
                <p>The odds of each Thimble Options are calculated according to multiplier 3 on winning.</p> 
                <p>Prize winning = Betting amount for the winning Thimble Option * 3 for that Thimble Option.</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ThimbleGameRules',
    methods: {
        viewClose(status) {
            if (window.navigator.userAgent.indexOf("Android") > -1) {
                Android.viewClose(status);        
            }
        }
    }
}
</script>