<template>
    <div class="luckyStatistics-wrapper">
        <div class="top-headerBox">
            <div class="top-headerBoxIn">
                <div class="backToApp" id="close" @click="viewClose('someStatus')">
                    <div class="angle"></div>
                </div>
                <div class="top-header-HD">
                    <h2>Statistics</h2>
                </div>
            </div>
            <div class="luckyStatistics-tabs">
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                        <div class="nav-link" :class="{ 'active': activeTab === 'luckyStatisticsBS' }"
                            @click="activateTab('luckyStatisticsBS')" data-toggle="tab" href="#luckyStatisticsBS"
                            role="tab">B/S
                        </div>
                    </li>
                    <li class="nav-item">
                        <div class="nav-link" :class="{ 'active': activeTab === 'luckyStatisticsOE' }"
                            @click="activateTab('luckyStatisticsOE')" data-toggle="tab" href="#luckyStatisticsOE"
                            role="tab">O/E
                        </div>
                    </li>
                    <li class="nav-item">
                        <div class="nav-link" :class="{ 'active': activeTab === 'luckyStatistics1000x' }"
                            @click="activateTab('luckyStatistics1000x')" data-toggle="tab" href="#luckyStatistics1000x"
                            role="tab">1000x
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="tab-content">
            <div class="tab-pane" :class="{ 'active': activeTab === 'luckyStatisticsBS' }" id="luckyStatisticsBS"
                role="tabpanel">
                <div class="tab-contentIn" v-if="isLoadedStatisticsBS">
                    <luckyStatisticsBS />
                </div>
            </div>
            <div class="tab-pane" :class="{ 'active': activeTab === 'luckyStatisticsOE' }" id="luckyStatisticsOE"
                role="tabpanel">
                <div class="tab-contentIn" v-if="isLoadedStatisticsOE">
                    <LuckyStatisticsOE />
                </div>
            </div>
            <div class="tab-pane" :class="{ 'active': activeTab === 'luckyStatistics1000x' }" id="luckyStatistics1000x"
                role="tabpanel">
                <div class="tab-contentIn" v-if="isLoadedStatistics1000x">
                    <LuckyStatistics1000x />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LuckyStatisticsBS from './LuckyStatisticsBS.vue'
import LuckyStatisticsOE from './LuckyStatisticsOE.vue'
import LuckyStatistics1000x from './LuckyStatistics1000x.vue'
export default {
    name: 'LuckyStatistics',
    components: {
        LuckyStatisticsBS,
        LuckyStatisticsOE,
        LuckyStatistics1000x
    },
    data() {
        return {
            activeTab: 'luckyStatisticsBS',
            isLoadedStatisticsBS: false,
            isLoadedStatisticsOE: false,
            isLoadedStatistics1000x: false,
        }
    },
    methods: {
        activateTab(tabId) {
            this.activeTab = tabId;
            if (tabId === 'luckyStatisticsBS' && !this.isLoadedStatisticsBS) {
                this.isLoadedStatisticsBS = true;
            }
            else if (tabId === 'luckyStatisticsOE' && !this.isLoadedStatisticsOE) {
                this.isLoadedStatisticsOE = true;
            }
            else if (tabId === 'luckyStatistics1000x' && !this.isLoadedStatistics1000x) {
                this.isLoadedStatistics1000x = true;
            }
        },
        //Close Webview on back arrow click
        viewClose(status) {
            if (window.navigator.userAgent.indexOf("Android") > -1) {
                Android.viewClose(status);        
            }
        }
    },
    mounted() {
        this.isLoadedStatisticsBS = true;
    },
}
</script>