<template>
  <div class="outerWrapper">
    <router-view />
  </div>
</template>

<script>
import './assets/css/style.css';
import './assets/css/luckyNumber.css'
import './assets/fontsSegoe/stylesheet.css'
export default {
  name: 'App',
  components: {
  }
}
</script>