<template>
    <div>
        <div class="countryBox"  @click="openModal">
            <img :src="selectedCountry.icon" class="imgIcon" :alt="'Country Flag: ' + selectedCountry.name" />
            <span class="selectedCountryName">{{ selectedCountry.displayName }}</span>
            <img class="arrowImg" src="../../assets/images/downArrow.png" alt="arrow" />
         </div>
         <div class="modal" id="myModal" tabindex="-1" ref="myModal">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Select Country</h5>
                    <button type="button" class="popup-closeBtn" @click="closeModal">
                        <img src="../../assets/images/popupClosed.svg" alt="" />
                    </button>
                </div>
                <div class="modal-body">
                    <div class="popupboxSmall">
                        <ul class="radioCustomBtn radioCustomResponsive---">
                            <li v-for="country in countries" :key="country.name">
                                <div class="countryTabBox" :class="{ 'active': isSelectedCountry(country.name) }"  @click="selectCountry(country)">
                                    <img :src='country.icon' class="imgIcon" :alt="'Country Flag: ' + country.name" />{{ country.apiName }}
                                </div>
                            </li>                        
                        </ul>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CountryModal',
    data(){
        return {
            selectedCountry: {name:'India', displayName: 'India', icon: require('../../assets/images/india-icon.png'), apiName: 'India'}, // Default selected country
            countries: [
                { name: 'India', displayName: 'India', icon: require('../../assets/images/india-icon.png'), apiName: 'India' },
                { name: 'Pakistan', displayName: 'Pakistan', icon: require('../../assets/images/pakistan-icon.png'), apiName: 'Pakistan' },
                { name: 'Philippines', displayName: 'Philippines', icon: require('../../assets/images/philippines-icon.png'), apiName: 'Philippines' },
                { name: 'Nigeria', displayName: 'NRA/MG', icon: require('../../assets/images/nigeria-icon.png'), apiName: 'Nigeria/Madagascar' },
            ]
        }
    },
    methods: {
        selectCountry(country) {
            this.selectedCountry = country;
            this.$emit('CountryName-click', country);
            //this.$emit('list-item-click', country);
            this.closeModal();
         },
         isSelectedCountry(countryName) {
            return this.selectedCountry.name === countryName;
         },
                   
         openModal() {
            // Show the modal
            this.$refs.myModal.classList.add('show');
            this.$refs.myModal.style.display = 'block';
            document.body.addEventListener('click', this.preventClose);
            document.body.classList.add('modal-open');
         },
         closeModal() {
            this.$refs.myModal.classList.remove('show');
            this.$refs.myModal.style.display = 'none';
            document.body.removeEventListener('click', this.preventClose);
            document.body.classList.remove('modal-open');
         },         
         preventClose(event) {
            if (!this.$refs.myModal.contains(event.target)) {
            event.stopPropagation();
            }
         },
    }
}
</script>