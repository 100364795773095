<template>
    <div class="historyTable-wrapper carGame-historyTable">
        <LoadingOverlay :loading="loading" class="popupLoading" />
        <div class="table-responsive" ref="tableContainer">
            <table class="table">
                <thead>
                    <tr>
                        <th>Time</th>
                        <th style="width: 95px">Result</th>
                        <th style="width: 96.17px;">My Bet <span class="diamond-iconBox"><img :src='diamondIcon' alt="" /></span></th>
                        <th>Prizes<span class="diamond-iconBox"><img :src='diamondIcon' alt="" /></span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="myBet in carGameBetMyHistory" :key="myBet.contestid">
                        <td>{{ myBet.contestid_hm }}</td>
                        <td>
                            <div class="history-carImage">
                                <img :src="getCarImageSrc(myBet.winning_car)" class="car-img" :alt="myBet.winning_car" />
                            </div>                            
                        </td>
                        <td>{{ myBet.total_bet }}</td>
                        <td>
                            <router-link :to="generateHistoryDetailsLink(myBet.contestid)" class="go-histortDetails">
                                {{ myBet.total_won }} <span class="angle"></span>
                            </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="scrollLoading" class="scrollLoading">Loading...</div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { RouterLink } from 'vue-router';
import LoadingOverlay from '../LoadingOverlay.vue'
import diamondIcon from '../../assets/images/diamond.png'
export default {
    name: 'CarGameMyHistory',
    components: {
        LoadingOverlay,
        RouterLink
    },
    data() {
        return {
            carGameBetMyHistory: [],
            diamondIcon: diamondIcon,
            currentPage: 1,
            loading: false,
            scrollLoading: false,
            totalPages: 1
        }
    },
    mounted() {
        this.userId = this.$route.params.user_id;
        this.carGameFetchBetMyHistory();
        this.setupScrollListener();
    },
    methods: {
        carGameFetchBetMyHistory() {
            if (this.loading || this.scrollLoading) return;
            this.scrollLoading = this.currentPage > 1;
            this.loading = this.currentPage === 1;
            const apiUrl = `https://zeep.live/api/cargame/mybethistoryapi.php?page=${this.currentPage}&user_id=${this.userId}`;
            axios.get(apiUrl)
                .then(response => {
                    this.carGameBetMyHistory = [...this.carGameBetMyHistory, ...response.data.data];
                    this.totalPages = response.data.total_pages;
                })
                .catch(error => {
                    console.error('Error fetching myhistory data:', error);
                })
                .finally(() => {
                    this.loading = false;
                    this.scrollLoading = false;
                })
        },
        getCarImageSrc(car) {
            // Remove spaces from the car name
            const sanitizedCarName = car.replace(/\s+/g, '');
            return require(`../../assets/images/car-game/${sanitizedCarName}.png`);
        },
        generateHistoryDetailsLink(contestId) {
            const userId = this.userId;
            return {
                path: '/CarGame/history-details/' +
                    `contest_id=${contestId}/user_id=${userId}`
            };
        },
        setupScrollListener() {
            const container = this.$refs.tableContainer;
            if (container) {
                container.addEventListener('scroll', this.handleScroll);
            }
        },
        handleScroll() {
            const container = this.$refs.tableContainer;
            if (!container) return;

            const scrollPosition = container.scrollTop + container.clientHeight;
            const pageHeight = container.scrollHeight;

            if ((this.loading || this.scrollLoading) == false && scrollPosition >= pageHeight - 100 && this.currentPage < this.totalPages) {
                this.currentPage++;
                this.carGameFetchBetMyHistory();
            }
        }
    },
}
</script>