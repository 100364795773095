<template>
    <div class="historyDetails-wrapper">
        <div class="historyDetails-topHeadBox">
            <div class="backToApp" id="close" @click="viewClose('someStatus')">
                <div class="angle"></div>
            </div>
            <div class="historyDetails-topHD">
                <h2>Rule</h2>
            </div>
        </div>
        <div class="ruleInfo-wrapper">
            <div class="ruleContent">
                <p>The odds of each number are calculated according to dynamic odds.</p>
                <p>Prize winning = Betting amount for the winning number* Dynamics odds for that number.</p>
                <p>Dynamic odds = total bet amount / total bet amount of this number.</p>
                <p>when the dynamic odds are higher than the standard odds, a part of the prizes will be distributed to
                    the host and guests.</p>
                <p><b>Please note</b> that using more than 20 numners may increase the risk of losses.</p>
                <div class="ruleCont-subBox">
                    <h4>Equal Amount Betting</h4>
                    <p>All selected numbers are bet equally.</p>
                </div>
                <div class="ruleCont-subBox">
                    <h4>Betting by Odds</h4>
                    <p>Bet according to the standard odds for each number.</p>
                </div>
                <h3 class="heading">Standard Odds</h3>
                <div class="rule-standardOdds-table">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>SUM</th>
                                <th>Prize Odds</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>0</td>
                                <td>
                                    <div class="bet">1000.0x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>
                                    <div class="bet">333.3x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>
                                    <div class="bet">166.7x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>
                                    <div class="bet">100.0x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>
                                    <div class="bet">66.7x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>
                                    <div class="bet">47.6x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>
                                    <div class="bet">35.7x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>
                                    <div class="bet">27.8x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>
                                    <div class="bet">22.2x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>
                                    <div class="bet">18.2x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>
                                    <div class="bet">15.9x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>
                                    <div class="bet">14.5x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>
                                    <div class="bet">13.7x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>13</td>
                                <td>
                                    <div class="bet">13.3x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>14</td>
                                <td>
                                    <div class="bet">13.3x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>15</td>
                                <td>
                                    <div class="bet">13.7x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>16</td>
                                <td>
                                    <div class="bet">14.5x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>17</td>
                                <td>
                                    <div class="bet">15.9x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>18</td>
                                <td>
                                    <div class="bet">18.2x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>19</td>
                                <td>
                                    <div class="bet">22.2x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td>
                                    <div class="bet">27.8x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>21</td>
                                <td>
                                    <div class="bet">35.7x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>22</td>
                                <td>
                                    <div class="bet">47.6x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>23</td>
                                <td>
                                    <div class="bet">66.7x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>24</td>
                                <td>
                                    <div class="bet">100.0x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>25</td>
                                <td>
                                    <div class="bet">166.7x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>26</td>
                                <td>
                                    <div class="bet">333.3x</div>
                                </td>
                            </tr>
                            <tr>
                                <td>27</td>
                                <td>
                                    <div class="bet">1000.0x</div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Rules',
    methods: {
        //Close Webview on back arrow click
        viewClose(status) {
            if (window.navigator.userAgent.indexOf("Android") > -1) {
                Android.viewClose(status);        
            }
        }
    }
}
</script>