<template>
   <div class="">
      <div class="listToggle awwardsLead">
         <!-- Nav tabs -->
         <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item" @click="hideCountryBox">
               <div class="nav-link" :class="{ 'active': activeTab === 'daily' }" @click="activateTab('daily')"
                  data-toggle="tab" href="#daily" role="tab">Daily</div>
            </li>
            <li class="nav-item" @click="showCountryBox">
               <div class="nav-link" :class="{ 'active': activeTab === 'weekly' }" @click="activateTab('weekly')"
                  data-toggle="tab" href="#weekly" role="tab">Weekly</div>
            </li>
         </ul>
         <CountryModal v-show="isCountryBoxVisible" @CountryName-click="handleCountryNameClick" />
      </div>
      <!-- Tab panes -->
      <div class="tab-content">
         <div class="tab-pane" :class="{ 'active': activeTab === 'daily' }" id="daily" role="tabpanel">
            <div v-if="isLoadedDaily">
               <TopCharmDailyTabCont />
               <TopCharmDailyInnerTabs />
            </div>
         </div>
         <div class="tab-pane" :class="{ 'active': activeTab === 'weekly' }" id="weekly" role="tabpanel">
            <div v-if="isLoadedWeekly">
               <TopCharmWeeklyTabCont :selectedCountryName="selectedCountryName" />
               <TopCharmWeeklyInnerTabs :selectedCountryName="selectedCountryName" />
            </div>
         </div>
      </div>
      <!-- Tabs Section End -->
   </div>
</template>
<script>
import TopCharmDailyTabCont from './TopCharmDailyTabCont.vue';
import TopCharmWeeklyTabCont from './TopCharmWeeklyTabCont.vue';
import TopCharmDailyInnerTabs from './TopCharmDailyInnerTabs.vue';
import TopCharmWeeklyInnerTabs from './TopCharmWeeklyInnerTabs.vue'
import CountryModal from './CountryModal.vue'

export default {
   name: 'TopCharm',
   components: {
      TopCharmDailyTabCont,
      TopCharmWeeklyTabCont,
      TopCharmDailyInnerTabs,
      TopCharmWeeklyInnerTabs,
      CountryModal,
   },
   data() {
      return {
         activeTab: 'weekly',
         isLoadedDaily: false,
         isLoadedWeekly: false,
         isCountryBoxVisible: true,
         selectedCountryName: ''
      };
   },
   methods: {
      activateTab(tabId) {
         this.activeTab = tabId;
         if (tabId === 'daily' && !this.isLoadedDaily) {
            this.isLoadedDaily = true;
         }
         if (tabId === 'weekly' && !this.isLoadedWeekly) {
            this.isLoadedWeekly = true;
         }
      },
      showCountryBox() {
         this.isCountryBoxVisible = true;
      },
      hideCountryBox() {
         this.isCountryBoxVisible = false;
      },

      handleCountryNameClick(country) {
         this.selectedCountryName = country.name;
      }
   },
   mounted() {
      this.isLoadedWeekly = true;
   },
}
</script>