<template>
   <div>
      <LoadingOverlay :loading="loading" />
      <div class="fixHeight">
         <div class="listBoxes" v-for="(reward, index) in thisWeekData" :key="reward.id"
            @click="redirectProfile(reward.id, reward.profile_id, reward.charm_level)">
            <div class="leftBxb">
               <h4 v-if="index > 2">{{ index + 1 }}</h4>
               <img v-else-if="index === 0" :src='rank1' class="rankNumbImage" alt="Reward Rank Image" />
               <img v-else-if="index === 1" :src='rank2' class="rankNumbImage" alt="Reward Rank Image" />
               <img v-else-if="index === 2" :src='rank3' class="rankNumbImage" alt="Reward Rank Image" />
               <div class="listIconBox">
                  <div class="profPic">
                     <img
                        :src='(reward.profile_images.length > 0) ? reward.profile_images[0].image_name : defaultUserImage'
                        class="user" alt="User Image" />
                  </div>
                  <img class="frames" :src='reward.current_pic_frame' alt="" />
               </div>
               <span>
                  <div :class="{ 'marqueeContainer': reward.name.length > 8 }">
                     <div v-if="reward.name.length > 8" class="marquee">
                        <span class="marquee-content">{{ reward.name }}</span>
                     </div>
                     <div v-else>{{ reward.name }}</div>
                  </div>

                  <div class="base">
                     <img :src='reward.current_rich_level_icon' /><b>{{ reward.rich_level }}</b>
                  </div>
               </span>
            </div>
            <div class="rightBxb">
               <span><img :src='diamondIcon' alt="Diamond Icon" />{{ reward.weekly_spend_beans }}</span>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import axios from 'axios'
import diamondIcon from '../../assets/images/diamond.png'
import rank1 from '../../assets/images/rank-1.png';
import rank2 from '../../assets/images/rank-2.png'
import rank3 from '../../assets/images/rank-3.png'
import defaultUserImage from '../../assets/images/default_profile.jpeg';
import LoadingOverlay from '../LoadingOverlay.vue'
export default {
   name: 'TopRichThisWeekListDetailsComp',
   components: {
      LoadingOverlay
   },
   data() {
      return {
         thisWeekData: [],
         diamondIcon: diamondIcon,
         rank1: rank1,
         rank2: rank2,
         rank3: rank3,
         defaultUserImage: defaultUserImage,
         loading: false,
      }
   },
   mounted() {
      this.fetchWeeklyRewards();
   },
   methods: {
      fetchWeeklyRewards() {
         this.loading = true;
         axios.get('https://zeep.live/api/top-rich-weekly?type=this_week').then(response => {
            this.thisWeekData = response.data.result.data;
            this.checkAllImagesExist();
         })
            .catch(error => {
               console.error('Error fetching weekly rewards:', error);
            })
            .finally(() => {
               this.loading = false;
            })
      },
      checkAllImagesExist() {
         this.thisWeekData.forEach(reward => {
            if (reward.profile_images.length > 0) {
               const imageUrl = reward.profile_images[0].image_name;
               this.checkImageExists(imageUrl)
                  .then(exists => {
                     if (!exists) {
                        reward.profile_images[0].image_name = this.defaultUserImage;
                     }
                  });
            }
         });
      },
      checkImageExists(imageUrl) {
         return new Promise((resolve, reject) => {
            var img = new Image();
            img.onload = function () {
               resolve(true);
            };
            img.onerror = function () {
               resolve(false);
            };
            img.src = imageUrl;
         });
      },

      redirectProfile(id, profile_id, level) {
         // if (window.navigator.userAgent.indexOf("Android") > -1) {
         // const baseURL = 'female-weekly-rewards';
         const baseURL = '#';
         const params = {
            id: id,
            profile_id: profile_id,
            level: level
         };

         const queryString = Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');
         const url = `${baseURL}?${queryString}`;
         window.location.href = url;
         // Android.redirectProfile(url);
         // }
      }
   },
} 
</script>