<template>
    <div class="rewards-bg">
        <h3 class="txtb">Current Reward</h3>
        <img :src='pinkBarBg' class="date-bg" alt="Pink Topbar Image" />
        <div class="afterbefore"></div>
        <img class="list-top-bg" :src='pinkBackgroundImage' alt="Pink Background Image" />
        <div class="daily-reward-box rewardPhoto">
            <div>
                <div class="coin-box">
                    <div class="coin-text">
                        <div class="text-white">
                            <div class="img-img">
                                <img :src='zhibojianFansSecond' alt="Rank 2" />
                                <img :src='rank2' class="smallpoint" alt="Rank 2" />
                            </div>
                            <span class="beans-box silver">
                                <img :src='beansIcon' alt="Beans Icon" />
                                <span v-if="topCharmWeeklyReward.length > 1">{{ topCharmWeeklyReward[1].reward_coin }}</span>
                            </span>
                        </div>
                    </div>
                    <div class="coin-text active">
                        <div class="text-white">
                            <div class="img-img">
                                <img class="activeZoom" :src='zhibojianFansFirst' alt="Rank 1" />
                                <img class="smallpoint" :src='rank1' alt="Rank 1" />
                            </div>
                            <span class="beans-box gold">
                                <img :src='beansIcon' alt="Beans Icon" />
                                <span v-if="topCharmWeeklyReward.length > 0">{{ topCharmWeeklyReward[0].reward_coin }}</span>                                
                            </span>
                        </div>
                    </div>
                    <div class="coin-text">
                        <div class="text-white">
                            <div class="img-img">
                                <img :src='zhibojianFansThird' alt="Rank 3" />
                                <img class="smallpoint" :src='rank3' alt="Rank 3" />
                            </div>
                            <span class="beans-box bronse">
                                <img :src='beansIcon' alt="Beans Icon" />
                                <span v-if="topCharmWeeklyReward.length > 0">{{ topCharmWeeklyReward[2].reward_coin }}</span>                                
                            </span>
                        </div>
                    </div>
                </div>
                <div class="mt-35" v-if="selectedCountryName === 'Pakistan' || selectedCountryName === 'Philippines' || selectedCountryName === 'Nigeria'">
                    <div class="weeklyBoth notIndia">
                    <!-- <div class="weeklyBoth" :class="{ 'fullRankBox': selectedCountryName === 'Pakistan' || selectedCountryName === 'Philippines' || selectedCountryName === 'Nigeria' }"> -->
                    <div class="rank-box">
                        <span>4th to 10th</span>
                        <div class="righticon">
                            <img :src='beansIcon' class="beandIcon" alt="Beans Icon" />
                            <span v-if="topCharmWeeklyReward.length > 3">{{ topCharmWeeklyReward[3].reward_coin }}</span>
                        </div>
                    </div>                    
                </div>
                </div>
                
                <div class="mt-35" v-else>
                    <div class="weeklyBoth ">
                        <div class="rank-box">
                            <span>4th to 10th</span>
                            <div class="righticon">
                                <img :src='beansIcon' class="beandIcon" alt="Beans Icon" />
                                <span v-if="topCharmWeeklyReward.length > 3">{{ topCharmWeeklyReward[3].reward_coin }}</span>
                            </div>
                        </div>
                        <div class="rank-box">
                        <span>11th to 20th</span>
                        <div class="righticon">
                            <img :src='beansIcon' class="beandIcon" alt="Beans Icon" />
                            <span v-if="topCharmWeeklyReward.length > 11">{{ topCharmWeeklyReward[11].reward_coin }}</span>
                        </div>
                    </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import pinkBarBg from '../../assets/images/pink-bar-bg.png'
import pinkBackgroundImage from '../../assets/images/pink-background-image.png';
import zhibojianFansSecond from '../../assets/images/zhibojian_fans_sec.png';
import zhibojianFansFirst from '../../assets/images/zhibojian_fans_first.png';
import zhibojianFansThird from '../../assets/images/zhibojian_fans_thi.png';
import rank1 from '../../assets/images/rank-1.png'
import rank2 from '../../assets/images/rank-2.png'
import rank3 from '../../assets/images/rank-3.png'
import beansIcon from '../../assets/images/beans-icon.png'
export default {
    name: 'TopCharmWeeklyTabCont',
    props: {
        // selectedCountryName: String,
        selectedCountryName: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            topCharmWeeklyReward: [],
            pinkBarBg: pinkBarBg,
            pinkBackgroundImage: pinkBackgroundImage,
            zhibojianFansSecond: zhibojianFansSecond,
            zhibojianFansFirst: zhibojianFansFirst,
            zhibojianFansThird: zhibojianFansThird,
            beansIcon: beansIcon,
            rank1: rank1,
            rank2: rank2,
            rank3: rank3,
        }
    },
    watch: {
        selectedCountryName(newCountryName, oldCountryName) {
            if (newCountryName !== oldCountryName) {
                this.fetchTopCharmWeeklyReward();
            }
        }
    },
    mounted() {
        this.fetchTopCharmWeeklyReward();
    },
    methods: {
        fetchTopCharmWeeklyReward() {
            const countryName = this.selectedCountryName || 'India';
            const apiUrl = `https://zeep.live/api/top-charm-weekly-daily?type=last_week&country=${countryName}`;
            // const apiUrl = `https://zeep.live/api/top-charm-weekly-daily?type=weekly&country=${this.selectedCountryName}`;
            //console.warn(apiUrl);
            axios.get(apiUrl).then(response => {
                this.topCharmWeeklyReward = response.data.result.rewards
            })
                .catch(error => {
                    console.error("Enter Featching Today Reward Data", error)
                })
        }
    },
}
</script>