<template>
    <div>
        <LoadingOverlay :loading="loading" class="popupLoading" />
        <div class="statistics1000x-listWrap" ref="tableContainer">
            <ul>
                <li v-for="(item, index) in newAr" :key="index">
                    <div class="statistics-progressBox">
                        <div class="statistics-progressIn" :style="{ width: getWidth(item.value) + '%' }">
                            <span v-if="index == 0" class="sp-baseBox">
                                <span class="sp-baseValue-0-27"></span>
                            </span>
                            <span v-if="index !== 0"
                                :class="{ 'sp-baseBox': item.key == 0, 'sp-baseBox active': item.key == 27 }">
                                <span class="sp-baseValue-0-27"><b v-if="item.key !== 'other'">{{ item.key }}</b></span>
                            </span>
                            <b class="sp-countlengthValue">{{ item.value + 1 }}</b>
                        </div>
                    </div>
                </li>
            </ul>
            <div v-if="scrollLoading" class="scrollLoading">Loading...</div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import LoadingOverlay from '../LoadingOverlay.vue'
export default {
    name: 'LuckyStatistics1000x',
    components: {
        LoadingOverlay
    },
    data() {
        return {
            count027Data: [],
            newAr: [],
            currentPage: 1,
            loading: false,
            scrollLoading: false,
            totalPages: 1,
            dataLength: 0,
            type: '027'
        }
    },
    mounted() {
        this.userId = this.$route.params.user_id;
        this.fetchCount027Data();
        this.setupScrollListener();
    },
    methods: {
        fetchCount027Data() {
            if (this.loading || this.scrollLoading) return;
            this.scrollLoading = this.currentPage > 1;
            this.loading = this.currentPage === 1;
            const apiUrl = `https://zeep.live/api/betapi/betstatisticsapi.php?page=${this.currentPage}&type=027&user_id=${this.userId}`;
            axios.get(apiUrl)
                .then(response => {
                    this.count027Data = [...this.count027Data, ...response.data.data];
                    this.transformData(); // Transform data after fetching
                    this.totalPages = response.data.total_pages; // Update totalPages dynamically
                })
                .catch(error => {
                    console.error('Error fetching history data:', error);
                })
                .finally(() => {
                    this.loading = false;
                    this.scrollLoading = false;
                })
        },

        setupScrollListener() {
            const container = this.$refs.tableContainer;
            if (container) {
                container.addEventListener('scroll', this.handleScroll);
            }
        },
        handleScroll() {
            const container = this.$refs.tableContainer;
            if (!container) return;

            const scrollPosition = container.scrollTop + container.clientHeight;
            const pageHeight = container.scrollHeight;

            if ((this.loading || this.scrollLoading) == false && scrollPosition >= pageHeight - 100 && this.currentPage < this.totalPages) {
                this.currentPage++;
                this.fetchCount027Data();
            }
        },
        transformData() {
            let newAr1 = [];
            let newAr = [];
            let j = 0;
            let k = 0;

            for (let i = 0; i < this.count027Data.length; i++) {
                if (this.count027Data[i].winning_no == 0 || this.count027Data[i].winning_no == 27) {
                    newAr1[j] = {
                        key: this.count027Data[i].winning_no,
                        value: k
                    };
                    j++;
                    k = 0; // Reset the column index
                } else {
                    newAr1[j] = {
                        key: 'other',
                        value: k
                    };
                    k++;
                }
            }
            for (let i = 0; i < newAr1.length; i++) {
                if (i == 0) {
                    newAr.push({
                        key: 'Other',
                        value: newAr1[i].value
                    });
                } else {
                    newAr.push({
                        key: newAr1[i - 1].key,  // use key from previous element
                        value: newAr1[i].value
                    });
                }
            }
            //console.log("newAr : ", newAr);
            this.newAr = newAr; // Update Vue reactive data
            this.dataLength = this.count027Data.length; // Update dataLength for percentage calculation

        },

        getWidth(value) {
            switch (true) {
                case (value + 1) >= 0 && (value + 1) <= 200:
                    return 10;
                case (value + 1) >= 201 && (value + 1) <= 400:
                    return 20;
                case (value + 1) >= 401 && (value + 1) <= 600:
                    return 30;
                case (value + 1) >= 601 && (value + 1) <= 800:
                    return 40;
                case (value + 1) >= 801 && (value + 1) <= 1000:
                    return 50;
                case (value + 1) >= 1001 && (value + 1) <= 1200:
                    return 60;
                case (value + 1) >= 1201 && (value + 1) <= 1400:
                    return 70;
                case (value + 1) >= 1401 && (value + 1) <= 1600:
                    return 80;
                case (value + 1) >= 1601 && (value + 1) <= 1800:
                    return 90;
                case (value + 1) >= 1801 && (value + 1) <= 2000:
                    return 100;
                default:
                    return 100;
            }
        }

    }
}
</script>