<template>
    <div class="luckyRewards-wrapper">
        <div class="backgroundWrapper">
            <div class="top-headerBox">
                <div class="top-headerBoxIn">
                    <div class="backToApp" id="close" @click="viewClose('someStatus')">
                        <div class="angle white-angle"></div>
                    </div>
                    <div class="top-header-HD">
                        <h2 class="white-txt">Rank</h2>
                    </div>
                </div>
                <div class="listToggle">
                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item">
                            <div class="nav-link" :class="{ 'active': activeTab === 'thisweek' }"
                                @click="activateTab('thisweek')" data-toggle="tab" href="#thisweek" role="tab">This Week
                            </div>
                        </li>
                        <li class="nav-item" @click="hideTimesBox">
                            <div class="nav-link" :class="{ 'active': activeTab === 'lastweek' }"
                                @click="activateTab('lastweek')" data-toggle="tab" href="#lastweek" role="tab">Last Week
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="tab-content">
                <div class="tab-pane" :class="{ 'active': activeTab === 'thisweek' }" id="thisweek" role="tabpanel">
                    <div class="tab-contentIn" v-if="isLoadedThisWeek">
                        <CarGameRankThisWeek />
                    </div>
                </div>
                <div class="tab-pane" :class="{ 'active': activeTab === 'lastweek' }" id="lastweek" role="tabpanel">
                    <div class="tab-contentIn" v-if="isLoadedLastWeek">
                        <CarGameRankLastWeek />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CarGameRankThisWeek from './CarGameRankThisWeek.vue'
import CarGameRankLastWeek from './CarGameRankLastWeek.vue'
export default {
    name: 'CarGameRankTabComponent',
    components: {
        CarGameRankThisWeek,
        CarGameRankLastWeek,
    },
    data() {
        return {
            activeTab: 'thisweek',
            isLoadedThisWeek: false,
            isLoadedLastWeek: false,
        };
    },
    methods: {
        activateTab(tabId) {
            this.activeTab = tabId;
            if (tabId === 'thisweek' && !this.isLoadedThisWeek) {
                this.isLoadedThisWeek = true;
            }
            if (tabId === 'lastweek' && !this.isLoadedLastWeek) {
                this.isLoadedLastWeek = true;
            }
        },
        //Close Webview on back arrow click
        viewClose(status) {
            if (window.navigator.userAgent.indexOf("Android") > -1) {
                Android.viewClose(status);        
            }
        }
    },
    mounted() {
        this.isLoadedThisWeek = true;
    },
}
</script>