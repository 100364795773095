<template>
   <div>
      <LoadingOverlay :loading="loading" />
      <div class="rewards-bg">
         <h3 class="txtb">Yesterday</h3>
         <img :src='pinkBarBg' class="date-bg" alt="Pink Topbar Image" />
         <div class="afterbefore"></div>
         <img class="list-top-bg" :src='pinkBackgroundImage' alt="Pink Background Image" />
         <div class="daily-reward-box rewardPhoto">
            <div class="wrapDiv">
               <div class="coin-box">
                  <div class="coin-text">
                     <div class="text-white"
                        @click="redirectProfile(TopCharmYesterdayData[1].user.id, TopCharmYesterdayData[1].user.profile_id, TopCharmYesterdayData[1].user.charm_level)">
                        <div class="img-img">
                           <div class="overlap">
                              <img :src='zhibojianFansSecond' alt="Rank 2 Section Image">
                              <div class="coin-profPic" v-if="TopCharmYesterdayData.length > 1">
                                 <img class="overlapImg"
                                    :src='TopCharmYesterdayData[1].user.profile_images[0].image_name'
                                    alt="User Image" />
                              </div>
                           </div>
                           <img class="smallpoint" :src='rank2' alt="Rank 2">
                           <div class="pointsBox" v-if="TopCharmYesterdayData.length > 1">
                              <img class="points" :src='TopCharmYesterdayData[1].user.last_charm_level_icon'
                                 alt="Label Image">
                              <span class="value">{{ TopCharmYesterdayData[1].user.charm_level }}</span>
                           </div>
                        </div>
                        <div class="userInfo-box" v-if="TopCharmYesterdayData.length > 1">
                           <span class="name">
                              <div :class="{ 'marqueeContainer': TopCharmYesterdayData[1].user.name.length > 8 }">
                                 <div v-if="TopCharmYesterdayData[1].user.name.length > 8" class="marquee">
                                    <span class="marquee-content">{{ TopCharmYesterdayData[1].user.name }}</span>
                                 </div>
                                 <div v-else>{{ TopCharmYesterdayData[1].user.name }}</div>
                              </div>
                           </span>
                           <span class="spanBox beans-box">
                              <img :src='beansIcon' alt="Beans Icon" />{{ TopCharmYesterdayData[1].total_coin_earned }}
                           </span>
                           <span class="rewards">Reward
                              <span class="rewardsCoinVal">{{ TopCharmYesterdayData[1].reward_coin }}</span>
                           </span>
                        </div>

                     </div>
                  </div>
                  <div class="coin-text active">
                     <div class="text-white"
                        @click="redirectProfile(TopCharmYesterdayData[0].user.id, TopCharmYesterdayData[0].user.profile_id, TopCharmYesterdayData[0].user.charm_level)">
                        <div class="img-img">
                           <div class="overlap">
                              <img class="activeZoom" :src='zhibojianFansFirst' alt="Rank 1 Background" />
                              <div class="coin-profPicActive" v-if="TopCharmYesterdayData.length > 0">
                                 <img class="overlapImg overlapActive"
                                    :src='TopCharmYesterdayData[0].user.profile_images[0].image_name'
                                    alt="User Image" />
                              </div>
                           </div>
                           <img class="smallpoint" :src='rank1' alt="Rank 1" />
                           <div class="pointsBox" v-if="TopCharmYesterdayData.length > 0">
                              <img class="points" :src='TopCharmYesterdayData[0].user.last_charm_level_icon'
                                 alt="Label Image">
                              <span class="value">{{ TopCharmYesterdayData[0].user.charm_level }}</span>
                           </div>
                        </div>
                        <div class="userInfo-box" v-if="TopCharmYesterdayData.length > 0">
                           <span class="name" style="text-align: center;">
                              <div :class="{ 'marqueeContainer': TopCharmYesterdayData[0].user.name.length > 8 }">
                                 <div v-if="TopCharmYesterdayData[0].user.name.length > 8" class="marquee">
                                    <span class="marquee-content">{{ TopCharmYesterdayData[0].user.name }}</span>
                                 </div>
                                 <div v-else>{{ TopCharmYesterdayData[0].user.name }}</div>
                              </div>
                           </span>
                           <span class="spanBox beans-box" style="text-align: center;">
                              <img :src='beansIcon' alt="Beans Icon" />{{ TopCharmYesterdayData[0].total_coin_earned }}
                           </span>
                           <span class="rewards">Reward
                              <span class="rewardsCoinVal">{{ TopCharmYesterdayData[0].reward_coin }}</span>
                           </span>
                        </div>
                     </div>
                  </div>
                  <div class="coin-text">
                     <div class="text-white"
                        @click="redirectProfile(TopCharmYesterdayData[2].user.id, TopCharmYesterdayData[2].user.profile_id, TopCharmYesterdayData[2].user.charm_level)">
                        <div class="img-img">
                           <div class="overlap">
                              <img :src='zhibojianFansThird' alt="Rank 3 Section Image">
                              <div class="coin-profPic" v-if="TopCharmYesterdayData.length > 2">
                                 <img class="overlapImg"
                                    :src='TopCharmYesterdayData[2].user.profile_images[0].image_name'
                                    alt="User Image" />
                              </div>
                           </div>
                           <img class="smallpoint" :src='rank3' alt="Rank 3">
                           <div class="pointsBox" v-if="TopCharmYesterdayData.length > 2">
                              <img class="points" :src='TopCharmYesterdayData[2].user.last_charm_level_icon'
                                 alt="Label Image">
                              <span class="value">{{ TopCharmYesterdayData[2].user.charm_level }}</span>
                           </div>
                        </div>
                        <div class="userInfo-box" v-if="TopCharmYesterdayData.length > 2">
                           <span class="name">
                              <div :class="{ 'marqueeContainer': TopCharmYesterdayData[2].user.name.length > 8 }">
                                 <div v-if="TopCharmYesterdayData[2].user.name.length > 8" class="marquee">
                                    <span class="marquee-content">{{ TopCharmYesterdayData[2].user.name }}</span>
                                 </div>
                                 <div v-else>{{ TopCharmYesterdayData[2].user.name }}</div>
                              </div>
                           </span>
                           <span class="spanBox beans-box">
                              <img :src='beansIcon' alt="Beans Icon" />{{ TopCharmYesterdayData[2].total_coin_earned }}
                           </span>
                           <span class="rewards">Reward
                              <span class="rewardsCoinVal">{{ TopCharmYesterdayData[2].reward_coin }}</span>
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="podium">
                  <img class="podiumImg" :src='pinkPodiumBg' alt="Podium Image" />
               </div>
            </div>
         </div>
         <!-- User List Section -->
         <div class="listingPattern">
            <div class="fixHeight">
               <div class="listBoxes" v-for="(reward, index) in TopCharmYesterdayData.slice(3)" :key="reward.id"
                  @click="redirectProfile(reward.user.id, reward.user.profile_id, reward.user.charm_level)">
                  <div class="leftBxb">
                     <div class="s-no">{{ index + 4 }}</div>
                     <div class="listIconBox">
                        <div class="profPic">
                           <!-- <img :src='reward.user.profile_images[0].image_name' class="user" alt="User Image" /> -->
                           <img
                              :src='(reward.user.profile_images.length > 0) ? reward.user.profile_images[0].image_name : defaultUserImage'
                              class="user" alt="User Image" />
                        </div>
                        <img class="frames" :src='reward.user.last_pic_frame' alt="Frame Of Profile Pic" />
                     </div>
                     <span>
                        <div :class="{ 'marqueeContainer': reward.user.name.length > 8 }">
                           <div v-if="reward.user.name.length > 8" class="marquee">
                              <span class="marquee-content">{{ reward.user.name }}</span>
                           </div>
                           <div v-else>{{ reward.user.name }}</div>
                        </div>
                        <div class="base">
                           <img :src='reward.user.last_charm_level_icon' alt="Charm Level Image" />
                           <b>{{ reward.user.charm_level }}</b>
                        </div>
                     </span>
                  </div>
                  <div class="rightBxb">
                     <span>
                        <img :src='beansIcon' alt="Beans Icon" />{{ reward.total_coin_earned }}<br />
                        <span class="rewards" v-if="index < 7">Reward {{ reward.reward_coin }}</span>
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import axios from 'axios';
import pinkBarBg from '../../assets/images/pink-bar-bg.png'
import pinkBackgroundImage from '../../assets/images/pink-background-image.png';
import zhibojianFansSecond from '../../assets/images/zhibojian_fans_sec.png';
import zhibojianFansFirst from '../../assets/images/zhibojian_fans_first.png';
import zhibojianFansThird from '../../assets/images/zhibojian_fans_thi.png';
import rank1 from '../../assets/images/rank-1.png';
import rank2 from '../../assets/images/rank-2.png';
import rank3 from '../../assets/images/rank-3.png';
import beansIcon from '../../assets/images/beans-icon.png';
import pinkPodiumBg from '../../assets/images/pink-podium-bg.png';
import defaultUserImage from '../../assets/images/default_profile.jpeg';
import LoadingOverlay from '../LoadingOverlay.vue'
export default {
   name: 'TopCharmDailyYesterdayTabCont',
   components: {
      LoadingOverlay
   },
   data() {
      return {
         TopCharmYesterdayData: [],
         pinkBarBg: pinkBarBg,
         pinkBackgroundImage: pinkBackgroundImage,
         zhibojianFansSecond: zhibojianFansSecond,
         zhibojianFansFirst: zhibojianFansFirst,
         zhibojianFansThird: zhibojianFansThird,
         beansIcon: beansIcon,
         rank1: rank1,
         rank2: rank2,
         rank3: rank3,
         pinkPodiumBg: pinkPodiumBg,
         defaultUserImage: defaultUserImage,
         loading: false,
      }
   },
   mounted() {
      this.fetchTopCharmTodayData()
   },
   methods: {
      fetchTopCharmTodayData() {
         this.loading = true;
         axios.get('https://zeep.live/api/top-charm-weekly-daily?type=yesterday&country=India').then(response => {
            this.TopCharmYesterdayData = response.data.result.data;
            this.checkAllImagesExist();
         })
            .catch(error => {
               console.error("Enter Featching Yesterday Data", error)
            })
            .finally(() => {
               this.loading = false;
            })
      },
      checkAllImagesExist() {
         this.TopCharmYesterdayData.forEach(reward => {
            if (reward.user.profile_images.length > 0) {
               const imageUrl = reward.user.profile_images[0].image_name;
               this.checkImageExists(imageUrl)
                  .then(exists => {
                     if (!exists) {
                        reward.user.profile_images[0].image_name = this.defaultUserImage;
                     }
                  });
            }
         });
      },
      checkImageExists(imageUrl) {
         return new Promise((resolve, reject) => {
            var img = new Image();
            img.onload = function () {
               resolve(true);
            };
            img.onerror = function () {
               resolve(false);
            };
            img.src = imageUrl;
         });
      },
      // Get User ingo on click 
      redirectProfile(id, profile_id, level) {
         if (window.navigator.userAgent.indexOf("Android") > -1) {
            const baseURL = 'female-weekly-rewards';
            const params = {
               id: id,
               profile_id: profile_id,
               level: level
            };
            const queryString = Object.keys(params)
               .map(key => `${key}=${params[key]}`)
               .join('&');
            const url = `${baseURL}?${queryString}`;
            Android.redirectProfile(url);
         }
      }
   }
}
</script>