<template>
    <div v-if="isVisible" class="countdown-container">
        {{ counterDownTime }}
    </div>
</template>
<script>
export default {
    name: 'CounterdownCompo',
    props: {
        isVisible: {
            type: Boolean,
            default: true
        },
        showDays: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            //weeklyFormatTime: ''
            counterDownTime: ''
        }
    },
    methods: {
        updateCountdown() {
            var now = new Date();
            var end = new Date();
            end.setHours(23, 59, 59);  // Set end time to 11:59:59 PM
            // end.setDate(end.getDate() + (7 - now.getDay()));  // Set end date to next Sunday

            if (now.getDay() == 0) {
                end.setDate(end.getDate());
            } else {
                end.setDate(end.getDate() + (7 - now.getDay()));
            }

            var timeDifference = end - now;
            if (timeDifference <= 0) {
                this.counterDownTime = "Countdown expired";
                return;
            }
            const { days, hours, minutes, seconds } = this.calculateTimeDifference(timeDifference);
            this.counterDownTime = this.formatTime(days, hours, minutes, seconds);
        },
        calculateTimeDifference(timeDifference) {
            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
            return { days, hours, minutes, seconds };
        },
        formatTime(days, hours, minutes, seconds) {
            let formattedTime = '';
            if (this.showDays) {
                formattedTime += `${days}D: `;
            }
            formattedTime += `${hours}: ${minutes}: ${seconds}`;
            return formattedTime;
        }
    },
    created() {
        this.updateCountdown();
        setInterval(this.updateCountdown, 1000);
    }
}
</script>