<template>
    <div class="countdown-boxesIn">
        <div class="time-box"><span>{{ hours }}</span></div> :
        <div class="time-box"><span>{{ minutes  }}</span></div> :
        <div class="time-box"><span>{{ seconds  }}</span></div>
    </div>
</template>
<script>
export default {
    name: 'TopGameCountDown',
    data() {
        return {
            //days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        };
    },
    mounted() {
        this.makeTimerZeeplive();
        setInterval(() => {
        this.makeTimerZeeplive();
        }, 1000);
    },
    methods: {
        makeTimerZeeplive() {
            let endTime = new Date("2024-01-01T00:00:00+05:30");
            endTime = Date.parse(endTime) / 1000;
            let now = new Date();
            now = Date.parse(now) / 1000;
            let timeLeft = endTime - now;
            this.days = Math.floor(timeLeft / 86400);
            this.hours = Math.floor((timeLeft - this.days * 86400) / 3600);
            this.minutes = Math.floor(
                (timeLeft - this.days * 86400 - this.hours * 3600) / 60
            );
            this.seconds = Math.floor(
                timeLeft - this.days * 86400 - this.hours * 3600 - this.minutes * 60
            );
            if (this.hours < 10) {
                this.hours = "0" + this.hours;
            }
            if (this.minutes < 10) {
                this.minutes = "0" + this.minutes;
            }
            if (this.seconds < 10) {
                this.seconds = "0" + this.seconds;
            }
        }
    }
}
</script>